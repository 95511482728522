/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, YouTube, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-19m9rcu --style3 --full --parallax" name={"uvod"} animS={"3"} border={""} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/21e6ead38e034d0da3acdcd02af34745_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/21e6ead38e034d0da3acdcd02af34745_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/21e6ead38e034d0da3acdcd02af34745_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/21e6ead38e034d0da3acdcd02af34745_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/21e6ead38e034d0da3acdcd02af34745_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/21e6ead38e034d0da3acdcd02af34745_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/21e6ead38e034d0da3acdcd02af34745_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/21e6ead38e034d0da3acdcd02af34745_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"4"} animS={"3"}>
              
              <Title className="title-box fs--86 title-box--invert" style={{"maxWidth":890}} content={"Klíčová služba MOLEK"}>
              </Title>

              <Subtitle className="subtitle-box fs--43" content={"<span style=\"color: rgb(255, 255, 255);\">Zabezpečí Váš byt i domek</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"ir89u2xtg1s"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube fs={false} loop={true} embedId={"MDYr22Vfe8I"} autoplay={true} controls={false} ivLoadPolicy={false} modestbranding={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"rgba(224, 224, 224, 1)"}} anim={null} name={"informace"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--center" anim={""} animS={"3"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-supplementary);\">Montáže<br></span>"}>
              </Title>

              <Text className="text-box" content={"Provádíme montáže interiérových dveřních kování i kování bezpečnostních. Instalace zavíračů dveří, panikových kování na únikové cesty. Nabízíme možnost sjednocení cylindrických vložek a visacích zámků na jeden klíč. Montáže elektroniky pro ovládání dveří od jednodušších systémů jako je otevírač nezamčených dveří až po elektromotorické samozamykací zámky včetně pohonu dveřního křídla. "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400,"marginTop":0,"marginBottom":93.625}}>
              
              <Title className="title-box" style={{"maxWidth":403,"marginTop":0,"marginBottom":10}} content={"<span style=\"color: var(--color-supplementary);\">Přístupové systémy</span>"}>
              </Title>

              <Text className="text-box" style={{"marginBottom":0}} content={"Elektronické přístupové systémy umožňují kontrolovat kdo a kdy má kam umožněn přístup. Tyto systémy jsou elektronické a vstupy jsou vybaveny čtečkami čipových karet. \nDíky tomu je možné kontrolovat pohyb osob v budovách.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--30" style={{"maxWidth":400,"marginTop":6.049995422363281,"marginBottom":32.5374755859375}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-supplementary);\">Zvonkové systémy<br></span>"}>
              </Title>

              <Text className="text-box" content={"Instalujeme komunikační systémy od jednoduchých audio systémů až po systémy IP s možností přesměrování hovoru na mobilní telefon včetně obrazu. \nTyto systémy jde kombinovat s přístupovými systémy a dosáhnout tak komplexnějšího řešení kontroly vstupu do budovy."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"onqcnjgl7rn"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":132}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/489bb8c40ba849cb96d2735e5f70042a_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/489bb8c40ba849cb96d2735e5f70042a_s=350x_.png 350w"}>
              </Image>

              <Title className="title-box fs--30" content={"Výroba klíčů a servis<br><br>"}>
              </Title>

              <Text className="text-box" content={"Na naši prodejně Vám nabízíme možnost kopírování klíčů, prodej vložek, kování  a dalšího klíčového a dveřního příslušenství. "}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/makekey"} target={null} content={"Dozvědět se více"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":132}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/4d74c0eb8dc04083bcd8a1ccb225d155_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/4d74c0eb8dc04083bcd8a1ccb225d155_s=350x_.png 350w"}>
              </Image>

              <Title className="title-box fs--30" content={"Mechanické zabezpečení\n<br><br>"}>
              </Title>

              <Text className="text-box" content={"Kompletní řešení mechanického zabezpečení budov. Montáže kování, zámků, cylindrických vložek.<br><br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/mechsecurity"} content={"Dozvědět se více"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":132}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/b57dabe5c89e4229aee6808bdfca0ee9_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/b57dabe5c89e4229aee6808bdfca0ee9_s=350x_.png 350w"}>
              </Image>

              <Title className="title-box fs--30" content={"Systém <br>generálního klíče"}>
              </Title>

              <Text className="text-box" content={"Návrh a realizace systémů mechanického řízení přístupu a pohybu osob.<br><br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/sghk"} content={"Dozvědět se více"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"onqcnjgl7rn"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":132}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/f2cad25abc5543d78ce21dbe8b2648e4_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/f2cad25abc5543d78ce21dbe8b2648e4_s=350x_.png 350w"}>
              </Image>

              <Title className="title-box fs--30" content={"Zvonkové systémy\n"}>
              </Title>

              <Text className="text-box" content={"Návrh řešení a montáž zvonkových audio a video systémů pro bytové i rodinné domy."}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/homephone"} content={"Dozvědět se více"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":132}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/6c877c5c3e324a89b41c1397e94143e5_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/6c877c5c3e324a89b41c1397e94143e5_s=350x_.png 350w"}>
              </Image>

              <Title className="title-box fs--30" content={"Přístupové systémy"}>
              </Title>

              <Text className="text-box" content={"Montáže přístupových a docházkových elektronických systémů s lokální i vzdálenou správou."}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/elaccess"} content={"Dozvědět se více"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":132}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/2cb44daa36a2426e8e19b44ee4d252fd_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/2cb44daa36a2426e8e19b44ee4d252fd_s=350x_.png 350w"}>
              </Image>

              <Title className="title-box fs--30" content={"Kamerové systémy"}>
              </Title>

              <Text className="text-box" content={"Instalace kamerových systémů se záznamem a vzdáleným dohledem."}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/camerasystems"} content={"Dozvědět se více"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"onqcnjgl7rn"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":132}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/5be77888816f4f34996b831fbd9926ed_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19567/5be77888816f4f34996b831fbd9926ed_s=350x_.png 350w"}>
              </Image>

              <Title className="title-box fs--30" content={"Pohony vjezdových bran&nbsp;"}>
              </Title>

              <Text className="text-box" content={"Montáže pohonu bran pro <br>křídlové i posuvné brány"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/gate-drive"} content={"Dozvědět se více"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"reference"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}